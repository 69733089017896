import React, { useEffect, useState, useRef } from "react";
import {
    Nav,
    Button,
    Offcanvas,
    Dropdown, DropdownButton,
    Navbar,
    NavDropdown,
    Form,
    Container,
    Row,
    Col,
    Carousel,
    Card,
    CardTitle, Overlay, Popover
} from "react-bootstrap";
import ReactWhatsapp from 'react-whatsapp';

import gvtmlogo from "../assets/images/gvtmlogo.png";
import grlogo from "../assets/images/grlogo.png";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import x from "../assets/images/x.png";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import youtube from "../assets/images/youtube.png";
import linkedin from "../assets/images/linkedin.png";
import appstore from "../assets/images/appstore.png";
import imageplay from "../assets/images/imageplay.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { faFacebook, faInstagram, faLinkedin, faSquareWhatsapp, faSquareXTwitter, faWhatsapp, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
let district = "";
export default function Layout(props) {
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const [DDValue, setDDValue] = useState(null);
    const ref = useRef(null);


    const handleClick = (event) => {
        setShow(!show);
        setTarget(event.target);
    };
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShow(false);
        }
    };
    useEffect(() => {
        // Add the event listener when the component is mounted
        document.addEventListener("mousedown", handleClickOutside);

        // Remove the event listener when the component is unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const handleDropdownItemClick = (type) => {
        navigate(`/becomebusinesspartner/${type}`);
    };

    const handleVisionMissionClick = (e) => {
        e.preventDefault();
        navigate("/aboutus").then(() => {
            const element = document.getElementById("vision-mission");
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        });
    };
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);
    return (
        <div style={{ overflowX: "hidden" }}>
            {/* {[false].map((expand) => (
                //className={`${isSafari == true ? "safarinav" : "navbar-style"}`}
                <Navbar collapseOnSelect expand="lg" className="navbar-style">
                    <Container>
                        <Navbar.Brand href="/" >
                            <div className='logostyle'>
                                <img src={genivislogo} className='logostyle' />
                            </div>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Row>
                            <Col lg="12" sm="12" xs="12">
                                <Nav style={{ flexDirection: "row", }}   >

                                    <NavDropdown title="About" id="collapsible-nav-dropdown" className='me-4 menustyle d-none d-lg-block'>
                                        <NavDropdown.Item href="#action/3.1">Vission & Mission</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.2">
                                            Value & Strength
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.3">Biggest Milestone</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                            Administrative Team
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.5">
                                            Business Partner
                                        </NavDropdown.Item>
                                    </NavDropdown>

                                    <NavDropdown title="Gallery" id="collapsible-nav-dropdown1" className='me-4 menustyle d-none d-lg-block'>
                                        <NavDropdown.Item href="#action/3.1">Franchise Inaugurations</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.2">
                                            Training Program
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.3">Awards & Recognition's</NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.4">
                                            Advertisement Videos
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.5">
                                            Generic Medicine Awareness
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.6">
                                            Franchise Model Demo
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.6">
                                            Our Warehouse
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.6">
                                            Press Release
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.6">
                                            Marketing Activity
                                        </NavDropdown.Item>
                                    </NavDropdown>
                                    <NavDropdown title="Careers" id="collapsible-nav-dropdown" className='me-4 menustyle d-none d-lg-block'>
                                        <NavDropdown.Item href="#action/3.2">
                                            Why Us
                                        </NavDropdown.Item>
                                        <NavDropdown.Divider />
                                        <NavDropdown.Item href="#action/3.3">Jobs</NavDropdown.Item>

                                    </NavDropdown>
                                    <Nav.Link eventKey={3} href="blogs" className='me-4 menustyle d-none d-lg-block'>
                                        Blogs
                                    </Nav.Link>
                                    <Nav.Link eventKey={3} href="" className='me-4 menustyle d-none d-lg-block'>
                                        Contact
                                    </Nav.Link>
                                    <Button type="button" className='me-4 btn-style my-auto'>Become Partner</Button>
                                    <Button type="button" className='franchisebtn my-auto'>Franchise Enquiry</Button>

                                </Nav>



                            </Col>
                            {/* <Col lg="1" sm="1" xs="1" className='paddingmenubar'>
                                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
                                <Navbar.Offcanvas
                                    id={`offcanvasNavbar-expand-${expand}`}
                                    aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                                    placement="end"
                                >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='loginstyle'>
                                            Login
                                        </Offcanvas.Title>    |  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`} className='loginstyle'>
                                            Register
                                        </Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <Nav className="justify-content-end flex-grow-1 pe-3" style={{ flexDirection: "column" }}>
                                            <Nav.Link href="/" className="menuhover">Home</Nav.Link>
                                            <Nav.Link href="#action2" className="menuhover">Franchise</Nav.Link>
                                            <Nav.Link href="shop" className="menuhover">Shop</Nav.Link>
                                            <Nav.Link href="blogs" className="menuhover">Blogs</Nav.Link>
                                            <Nav.Link href="#action5" className="menuhover">Contact</Nav.Link>
                                            <hr />
                                            <Row>
                                                <Col lg="6" sm="6" xs="6">
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link1
                                                        </a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link2
                                                        </a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link3
                                                        </a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link4
                                                        </a>
                                                    </Nav.Link>
                                                </Col>
                                                <Col lg="6" sm="6" xs="6">
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link5</a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2">
                                                        <a href="" className="linkstyle">
                                                            Link6</a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2" >
                                                        <a href="" className="linkstyle">
                                                            Link7</a>
                                                    </Nav.Link>
                                                    <Nav.Link href="#action2" >
                                                        <a href="" className="linkstyle">
                                                            Link8</a>
                                                    </Nav.Link>
                                                </Col>
                                            </Row>
                                            <Row className="mt-5">
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={call} className="icon-style" />
                                                </Col>
                                                <Col lg="11" sm="11" xs="11">
                                                    <p className="contact-style">+919145009191</p>
                                                </Col>
                                            </Row>
                                            <Row >
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={gmail} className="icon-style" />
                                                </Col>
                                                <Col lg="11" sm="11" xs="11">
                                                    <p className="contact-style">prm@genivis.in</p>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={fb} className="icon-style" />
                                                </Col>
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={insta} className="icon-style" />
                                                </Col>
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={linkedin} className="icon-style" />
                                                </Col>
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={youtube} className="icon-style" />
                                                </Col>
                                                <Col lg="1" sm="1" xs="1">
                                                    <img src={x} className="icon-style" />
                                                </Col>
                                            </Row>
                                        </Nav>

                                    </Offcanvas.Body>
                                </Navbar.Offcanvas>
                            </Col>
                        </Row>
                    </Container>
                </Navbar>

            ))} */}
            {/*  */}
            <Navbar collapseOnSelect expand="lg" className={`${isSafari == true ? "navsafari" : "navbar-style"}`}>
                <Container>
                    <Navbar.Brand onClick={(e) => {
                        e.preventDefault();
                        navigate("/");
                    }}>
                        <div className='logostyle'>
                            <img src={gvtmlogo} className='logostyle' />
                        </div>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link eventKey={2} className='me-4 menustyle'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/");
                                }}
                            >
                                Home
                            </Nav.Link>
                            <NavDropdown title="About" id="collapsible-nav-dropdown" className='me-4 menustyle'

                            >

                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/aboutus");
                                    }}
                                >About Us</NavDropdown.Item>
                                <NavDropdown.Divider />
                                {/* <NavDropdown.Item href="#action/3.2">
                                    Value & Strength
                                </NavDropdown.Item> */}
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/biggestmilestone");
                                    }}
                                >Biggest Milestone</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/administrativeteam");
                                    }}
                                >
                                    Administrative Team
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/businesspartner");
                                    }}
                                >
                                    Business Partner
                                </NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link eventKey={3} className='me-4 menustyle'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/gallery");
                                }}
                            >
                                Gallery
                            </Nav.Link>
                            {/* <NavDropdown title="Gallery" id="collapsible-nav-dropdown1" className='me-4 menustyle '>
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/franchiseinauguration");
                                    }}
                                >Franchise Inaugurations</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/training");
                                    }}
                                >
                                    Training Program
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.3">Awards & Recognition's</NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.4">
                                    Advertisement Videos
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.5">
                                    Generic Medicine Awareness
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.6">
                                    Franchise Model Demo
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.6">
                                    Our Warehouse
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.6">
                                    Press Release
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item href="#action/3.6">
                                    Marketing Activity
                                </NavDropdown.Item>
                            </NavDropdown> */}
                            <NavDropdown title="Careers" id="collapsible-nav-dropdown" className='me-4 menustyle'>
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/whyus");
                                    }}
                                >
                                    Why Us
                                </NavDropdown.Item>
                                <NavDropdown.Divider />
                                <NavDropdown.Item
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/jobs");
                                    }}
                                >Jobs</NavDropdown.Item>

                            </NavDropdown>
                            <Nav.Link eventKey={4} className='me-4 menustyle' 
                                href="https://blogs.genivis.in/"
                                target="_blank"
                                rel="noopener noreferrer"
                                // onClick={(e) => {
                                //     e.preventDefault();
                                //     navigate("/blogs");
                                // }}
                            >
                                Blogs
                            </Nav.Link>

                            <Nav.Link eventKey={5} href="" className='me-4 menustyle'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/contact");
                                }}
                            >
                                Contact
                            </Nav.Link>
                            <Nav.Link eventKey={6} href="" className='me-4 menustyle'
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/franchise");
                                }}
                            >
                                Franchise
                            </Nav.Link>
                        </Nav>
                        <Nav style={{ flexDirection: "row" }}>
                            <div style={{ cursor: "pointer" }}>
                                <div ref={ref}>
                                    <img src={call} className="call me-2" onClick={handleClick} />
                                    <Overlay
                                        show={show}
                                        target={target}
                                        placement="bottom"
                                        container={ref}
                                        containerPadding={20}
                                    >
                                        <Popover id="popover-contained" style={{ backgroundColor: "#9DA5AC" }}>
                                            <Popover.Body style={{ padding: "5px" }}>
                                                <a href="tel:+919145009191" style={{ textDecoration: "none" }}>
                                                <strong style={{ color: "#fff", }}>+919145009191</strong>
                                                </a>
                                            </Popover.Body>
                                        </Popover>
                                    </Overlay>
                                </div>

                            </div>
                            <div onClick={() => document.getElementById('whatsapp-button').click()} style={{ display: 'inline-block', cursor: "pointer" }}>
                                <FontAwesomeIcon icon={faSquareWhatsapp} size="2x" className="whatsappstyle" />
                                <ReactWhatsapp
                                    number="91 4500 9191"
                                    message="Hello!!!"
                                    id="whatsapp-button"
                                    style={{ display: 'none', cursor: "pointer" }}
                                />
                            </div>

                            <div className=''>
                                {/* <Button type="button" className='btn-style my-auto'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        navigate("/becomebusinesspartner");
                                    }}
                                >Become a Business Partner</Button> */}
                                <Dropdown className='my-auto'>
                                    <Dropdown.Toggle id="dropdown-basic" className='btn-style my-auto'>
                                        Become a Business Partner
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDropdownItemClick('Franchise');
                                            }}
                                        >Franchise Partner</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDropdownItemClick('District');
                                            }}
                                        >District Franchise Partner</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDropdownItemClick('Regional');
                                            }}
                                        >Regional Franchise Partner</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDropdownItemClick('Zonal');
                                            }}
                                        >Zonal Franchise Partner</Dropdown.Item>
                                        <Dropdown.Item
                                            onClick={(e) => {
                                                e.preventDefault();
                                                handleDropdownItemClick('State');
                                            }}
                                        >State Franchise Partner</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                                {/* <Button type="button" className='franchisebtn my-auto'>Franchise Enquiry</Button> */}
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>


            {props.children}
            <div className='footer'>
                <Row className='justify-content-center'>
                    <Col lg="9">
                        <hr style={{ marginTop: "0px" }} />
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12">
                                <Row>
                                    <Col lg="7" md="9" sm="12" xs="12">
                                        <img src={gvtmlogo} className='footerlogo mb-4' />
                                        <div className="d-flex">
                                            <FontAwesomeIcon icon={faLocationDot} className="m-2 icon-style" style={{ color: "#2B4BB6" }} />
                                        <p className='footertxt'>
                                                Office No. 6070, 6th Floor, Marvel Fuego, Opp Seasons Mall, Magarpatta Road, Hadapsar, Pune 411028.
                                            </p>
                                        </div>

                                        <a href="tel:+919145009191" style={{ textDecoration: "none" }}>
                                            <Row className="mt-5" >
                                            <Col lg="1" sm="1" xs="1" className='pe-0'>
                                                <img src={call} className="icon-style" />
                                            </Col>
                                            <Col lg="11" sm="11" xs="11">
                                                <p className="contact-style">+919145009191</p>
                                            </Col>
                                            </Row>
                                        </a>

                                        <a href="mailto:prm@genivis.in" style={{ textDecoration: "none" }}>
                                        <Row >
                                            <Col lg="1" sm="1" xs="1" className='pe-0'>
                                                <img src={gmail} className="icon-style" />
                                            </Col>
                                            <Col lg="11" sm="11" xs="11">
                                                <p className="contact-style">prm@genivis.in</p>
                                            </Col>
                                        </Row>
                                        </a>
                                    </Col>
                                </Row>

                            </Col>
                            {/* <Col lg="1" md="1"></Col> */}
                            <Col lg="3" md="3" sm="6" xs="6" className='justify-content-center pe-0'>
                                <ul style={{ listStyleType: "none" }} className='ps-3 liststyle'>
                                    <h6 className='footerhead'>Company</h6>
                                    <li className='footertxt mb-2'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/");
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >Home</li>
                                    <li className='footertxt mb-2'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/aboutus");
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >About Us</li>
                                    <li className='footertxt mb-2'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/gallery");
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >Gallery</li>
                                    <li className='footertxt mb-2'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/jobs");
                                        }}
                                        style={{ cursor: "pointer" }}
                                    >Careers</li>
                                    <li className='footertxt mb-2'
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/contact");
                                        }}
                                    >Contact Us</li>
                                    <li className='footertxt mb-2'
                                    // onClick={(e) => {
                                    //     e.preventDefault();
                                    //     navigate("/blogs");
                                    // }}
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="https://blogs.genivis.in/"
                                            target="_blank"
                                            rel="noopener noreferrer" style={{ textDecoration: "none" }} className="footertxt">Blogs</a>
                                    </li>
                                    <li className='footertxt'
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate("/privacy");
                                        }}
                                    >Privacy Policy</li>
                                </ul>
                            </Col>
                            {/* <Col lg="2" md="3" sm="6" xs="6" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }} className='ps-3 liststyle'>
                                    <h6 className='footerhead'>Our Services</h6>
                                    <li className='footertxt mb-2'>Our Products</li>
                                    <li className='footertxt mb-2'>Comapre Medicine</li>
                                    <li className='footertxt mb-2'>Locate Nearest Store</li>
                                    <li className='footertxt mb-2'>Know Generics</li>

                                </ul>
                            </Col> */}
                            {/* <Col
                                lg="2"
                                md="2"
                                sm="4"
                                xs="4"
                                className="justify-content-center pe-0"
                            >
                                <ul
                                    style={{ listStyleType: "none" }}
                                    className="ps-3 liststyle"
                                >
                                    <h6 className="footerhead">Policies</h6>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >Privacy Policy</li>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >Terms & Conditions</li>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >Refund & Return Policy</li>
                                </ul>
                            </Col> */}
                            <Col
                                lg="3"
                                md="3"
                                sm="6"
                                xs="6"
                                className="justify-content-center pe-0"
                            >
                                <ul
                                    style={{ listStyleType: "none" }}
                                    className="ps-3 liststyle"
                                >
                                    <h6 className="footerhead">Follow Us</h6>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} className="footertxt">
                                        <FontAwesomeIcon icon={faFacebook} className="me-2" />
                                        Facebook
                                        </a>
                                    </li>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} className="footertxt">
                                        <FontAwesomeIcon icon={faInstagram} className="me-2" />
                                        Instagram
                                        </a>
                                    </li>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} className="footertxt">
                                        <FontAwesomeIcon icon={faLinkedin} className="me-2" />
                                        Linkedin
                                        </a>
                                    </li>
                                    <li className="footertxt mb-2"
                                        style={{ cursor: "pointer" }}
                                    >
                                        <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none" }} className="footertxt">
                                        <FontAwesomeIcon icon={faYoutube} className="me-2" />
                                        Youtube
                                        </a>
                                    </li>
                                    {/* <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faSquareXTwitter} className='me-2' />
                                        X(Twitter)
                                    </li> */}
                                </ul>
                            </Col>
                        </Row>
                        <Row className="text-center">
                            <Col lg="12" className="justify-content-center">
                                <div className="footerdiv text-center">
                                    <Row className="justify-content-center">
                                        <Col
                                            lg="4"
                                            md="4"
                                            sm="12"
                                            xs="12"
                                            className="text-end my-auto fttext"
                                        >
                                            <p className="mb-0 footertxt">
                                                Download the app by clicking the link:
                                            </p>
                                        </Col>
                                        <Col
                                            lg="4"
                                            md="4"
                                            sm="12"
                                            xs="12"
                                            className="text-start fttext"
                                        >
                                            <img src={imageplay} className="footerimg me-3" />
                                            <img src={appstore} className="footerimg" />
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            <Col lg="12" className="justify-content-center">
                                <p className="mt-2">
                                    ©Genivis Remedies (I) Pvt. Ltd. All rights reserved.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
}
